import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import PageMenu from "../components/PageMenu"
import LanguageDropdown from "../components/LanguageDropdown"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"
import LogoLink from "../components/LogoLink"

import { PageMetadataProvider } from "../pageMetadata"
import { getLanguages, isUiLanguage } from "../languages"

const Wrapper = styled.div`
  min-height: 100%;
  padding: 2rem 0 3.875rem;
  background: linear-gradient(
    0deg,
    ${props => props.theme.red[3]} 0%,
    ${props => props.theme.red[2]} 100%
  );
  background-attachment: fixed;

  @media (min-width: 530px) {
    padding-top: 2.75rem;
  }
`

const Header = styled.header`
  text-align: center;
`

const Intro = styled.div`
  max-width: 38rem;
  margin: 0 auto;
  text-align: center;
  color: white;

  h1 {
    margin: 1.375rem 0 0.625rem;
    padding: 0 1rem;
    font-size: 1.375rem;
    line-height: 2.1875rem;

    span {
      display: block;
      font-size: 1.125rem;
      line-height: 1.1;
    }
  }

  p {
    margin: 0;
    padding: 0 1rem;

    a {
      color: white;
      font-weight: bold;
    }
  }

  @media (min-width: 530px) {
    h1 span {
      display: inline;
      font-size: 1.375rem;
      line-height: 2.1875rem;
    }
  }
`

const HomePageLink = styled.p`
  margin: 1.75rem 0 2rem;
  text-align: center;

  a {
    color: white;
  }
`

const StyledPageMenu = styled(PageMenu)`
  padding-top: 0;
  background: transparent;

  li {
    border-color: rgba(255, 255, 255, 0.5);
  }

  &, a, button {
    &, :active, :focus, :hover {
      color: white;
    }
  }

  svg {
    fill: white;
  }
`

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.875rem;
  background: linear-gradient(
    0deg,
    ${props => props.theme.red[3]} 0%,
    ${props => props.theme.red[2]} 100%
  );
`

export default function Article404Template({
  data: { menuYaml, menuPages, pagesYaml },
  pageContext: { lang, svPath, enPath },
}) {
  const intl = useIntl()
  const uiLang = lang === "sv" ? "sv" : "en"

  const pageMetadata = {
    translations: Object.keys(getLanguages()).map((language) => ({
      lang: language,
      path: language === "sv" ? svPath : enPath,
    })),
  }

  const getForAdultsLink = () => (
    <Link to={`/${uiLang}${isUiLanguage(lang) ? "" : `/${lang}`}${pagesYaml.path}`}>
      {intl.formatMessage({ id: "goToTextPages" })}
    </Link>
  )

  return (
    <PageMetadataProvider value={pageMetadata}>
      <Wrapper>
        <NwHelmet
          lang={uiLang}
          path={`${isUiLanguage(lang) ? "" : `/${lang}`}${lang === "sv" ? svPath : enPath}`}
          title={intl.formatMessage({ id: "pageNotFound" })}
          is404
        />
        <Header>
          <LogoLink lang={uiLang} />
        </Header>
        <main>
          <Intro>
            <h1>{intl.formatMessage({ id: "article404Heading1" })} <span>{intl.formatMessage({ id: "article404Heading2" })}</span></h1>
            <p>{intl.formatMessage({ id: "article404Intro1" })} {getForAdultsLink()}. {intl.formatMessage({ id: "article404Intro2" })}</p>
          </Intro>
          <HomePageLink><Link to={`/${uiLang}`}>{intl.formatMessage({ id: "goBackHome" })}</Link></HomePageLink>
          <StyledPageMenu
            lang={lang}
            menuItems={get(menuYaml, "items")}
            pages={menuPages.nodes}
          />
        </main>
        <Footer>
          <LanguageDropdown
            uiLang={uiLang}
            activeLanguage={lang}
            up
          />
        </Footer>
      </Wrapper>
      <BrowserUpdateMessage />
      <CookieBanner lang={uiLang} />
    </PageMetadataProvider>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    pagesYaml(_id: { eq: "/valkommen-narkoswebben" }, lang: { eq: $lang }) {
      path
    }
    menuYaml(_id: { eq: "menu" }, lang: { eq: $lang }) {
      items {
        items {
          page
        }
        label
      }
    }
    menuPages: allPagesYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        _id
        path
        title
      }
    }
  }
`
