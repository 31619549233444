import styled from "styled-components"

const ButtonElement = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  font-smooth: inherit;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  appearance: none;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export default ButtonElement
