import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const LinkItem = styled(Link)`
  &:hover,
  &[aria-current="page"] {
    color: ${props => props.theme.linkColor};
  }

  &[aria-current="page"] {
    cursor: default;
  }
`

const PageLink = ({ to, lang, ...props }) => {
  return <LinkItem to={`/${lang === "sv" ? "sv" : "en"}${to}`} {...props} />
}

export default PageLink
