import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import { isUiLanguage, isRTL } from "../languages"

import PageLink from "./PageLink"
import PlusIcon from "./icons/Plus"
import MinusIcon from "./icons/Minus"
import ButtonElement from "./ButtonElement"

const MenuList = styled.ul`
  max-width: 40rem;
  margin: 0 auto;
  list-style: none;
  padding: 1.5rem;
  padding-bottom: 0.75rem;
  background: white;
  font-weight: 600;
  ${props => props.rtl && "text-align: right;"}
`

const MenuListItem = styled.li`
  border-top: 1px solid #efefef;
`

const MenuListItemHeader = styled(ButtonElement)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: left;
  cursor: pointer;

  &:focus {
    color: ${props => props.theme.headingColor};
  }

  &[dir="rtl"] {
    text-align: right;
  }
`

const MenuListItemLabel = styled.span`
  display: block;
  padding: ${props => props.rtl ? "1rem 0 1rem 0.75rem" : "1rem 0.75rem 1rem 0"};
  font-size: 1.125rem;
`

const MenuListItemLink = styled(PageLink)`
  display: block;
  text-decoration: inherit;
  color: inherit;
  padding: 1rem 0.5rem;
  font-size: 1.125rem;
`

const MenuListItemIcon = styled.span`
  display: block;
  color: ${props => props.theme.headingColor};
`

const SubmenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const SubmenuListItem = styled.li`
  border-top: 1px solid #efefef;
`

const SubmenuListItemLink = styled(PageLink)`
  display: block;
  text-decoration: inherit;
  color: inherit;
  padding: 1rem 1rem 1rem 1.5rem;

  &:focus {
    color: ${props => props.theme.headingColor};
  }
`

export const PageMenu = ({ items, lang, ...props }) => {
  const intl = useIntl()
  const [pathname, setPathname] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [expandedItems, setExpandedItems] = useState([])
  const rtl = isRTL(lang)

  useEffect(() => {
    setPathname(window.location.pathname.replace(`/${lang === "sv" ? "sv" : "en"}`, "").replace(/\/+$/g, ""))
  }, [])

  useEffect(() => {
    setActiveItem(items.findIndex((item) =>
      item.items.length > 1 && !!item.items.find((subItem) =>
        encodeURI(subItem.url) === pathname
      )
    ))
  }, [pathname])

  useEffect(() => {
    setExpandedItems([activeItem])
  }, [activeItem])

  return (
    <MenuList rtl={rtl} lang={lang} {...props}>
      {items.map(({ label, items }, index) => {
        if (!items.length) {
          return null
        }
        const isExpandable = items.length > 1
        const isExpanded = expandedItems.includes(index)
        const Icon = isExpanded ? MinusIcon : PlusIcon

        return (
          <MenuListItem key={index}>
            {isExpandable ?
              <MenuListItemHeader
                aria-expanded={isExpanded}
                onClick={() => {
                  isExpanded
                    ? setExpandedItems(
                        expandedItems.filter(item => item !== index)
                      )
                    : setExpandedItems([...expandedItems, index])
                }}
                {...(rtl ? { dir: "rtl" } : {})}
              >
                <MenuListItemLabel rtl={rtl}>{label}</MenuListItemLabel>
                <MenuListItemIcon>
                  <Icon role="none" />
                </MenuListItemIcon>
              </MenuListItemHeader>
              :
              <MenuListItemLink
                to={items[0].url}
                lang={lang}
                {...(encodeURI(items[0].url) === pathname ? { "aria-current": "page" } : {})}
                {...(rtl ? { dir: "rtl" } : {})}
              >
                {items[0].label}
              </MenuListItemLink>
            }
            {isExpanded &&
              <SubmenuList>
                {items.map(({ url, label }) => (
                  <SubmenuListItem key={url}>
                    <SubmenuListItemLink
                      to={url}
                      lang={lang}
                      {...(encodeURI(url) === pathname ? { "aria-current": "page" } : {})}
                      {...(rtl ? { dir: "rtl" } : {})}
                    >
                      {label}
                    </SubmenuListItemLink>
                  </SubmenuListItem>
                ))}
              </SubmenuList>
            }
          </MenuListItem>
        )
      })}
    </MenuList>
  )
}

const PageMenuContainer = ({ lang, pages, menuItems, ...props }) => {
  const transformedItems = menuItems.map((item) => {
    const transformedItem = Object.assign({}, item);

    transformedItem.items = get(transformedItem, "items", []).map((subItem) => {
      const page = pages.find((x) => x._id === subItem.page)

      return page ? {
        ...subItem,
        label: page.title,
        url: `${isUiLanguage(lang) ? "" : `/${lang}`}${page.path.toLowerCase().trim()}`
      } : null
    }).filter((n) => n)

    return transformedItem
  })

  return <PageMenu items={transformedItems} lang={lang} {...props} />
}

export default PageMenuContainer
