import React from "react"

const Minus = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="20" height="4" rx="2" y="8" />
  </svg>
)

export default Minus
