import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { theme } from "../theme"
import { usePageMetadata } from "../pageMetadata"
import { isUiLanguage, getLanguages, getLanguageName, getLanguageFlag, isRTL } from "../languages"

import ButtonElement from "./ButtonElement"
import Flag from "./Flag"
import CaretDownIcon from "./icons/CaretDown"
import CaretUpIcon from "./icons/CaretUp"

const Wrapper = styled.nav`
  position: relative;
  z-index: ${props => props.expanded ? "1000" : "auto"};
  color: white;
`

const Toggle = styled(ButtonElement)`
  cursor: pointer;
  padding: 0.5rem;
  margin: 0 0.5rem;
`

const ToggleGrid = styled.span`
  display: flex;
  align-items: center;
`

const OptionsList = styled.ul`
  overflow: auto;
  position: absolute;
  width: 12.5rem;
  ${props => !props.ui && "height: 24.5rem;"}
  max-height: ${props => props.ui ? "12.5rem" : "calc(100vh - 4.5rem)"};
  cursor: pointer;
  background: #fff;
  border-radius: 1rem;
  bottom: ${props => props.up ? "100%" : "auto"};
  ${props => props.left ? "right" : "left"}: 0;
  list-style: none;
  padding: 0.5rem 0;
  margin: 0.5rem 0 0 0;
  color: ${theme.textColor};
`

const LanguageText = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
  white-space: nowrap;
  font-family: ${props => props.theme.headingFont};
  font-style: normal;
  font-weight: normal;
`

const StyledFlag = styled(Flag)`
  flex-shrink: 0;
  margin-right: 0.5rem;
  pointer-events: none;
  border-radius: 50%;

  ${props => props.dropdown && `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.75rem;
    margin: auto;
  `}
`

const Option = styled.li`
  width: 100%;
  margin: 0;

  ${props => props.divider && `
    border-top: 1px solid rgba(209, 209, 209, 0.5);
  `}
`

const LanguageLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 3rem;
  color: inherit;
  text-decoration: inherit;

  &.active {
    background: #eee;

    ::before {
      content: "";
      position: absolute;
      right: .5rem;
      height: 0px;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 1rem;
      background: #666;
    }
  }

  &:hover {
    background: rgba(209, 209, 209, 0.5);
  }
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export function LanguageDropdown({
  up = false,
  left = false,
  links = {},
  uiLang,
  activeLanguage,
  ui = false,
  ...props
}) {
  const intl = useIntl()
  const [expanded, setExpanded] = useState(false)
  const languages = getLanguages(ui)
  const uiLanguages = ui ? languages : Object.keys(languages).reduce((acc, curr) => (
    languages[curr].ui ? Object.assign({}, acc, { [curr]: languages[curr] }) : acc
  ), {})
  const nonUiLanguages = ui ? [] : Object.keys(languages).reduce((acc, curr) => (
    languages[curr].ui ? acc : Object.assign({}, acc, { [curr]: languages[curr] })
  ), {})

  const closeDropdownOnEscape = useCallback(event => {
    if (event.keyCode === 27) {
      setExpanded(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", closeDropdownOnEscape, false)
    return () => {
      document.removeEventListener("keydown", closeDropdownOnEscape, false)
    }
  }, [])

  const Caret = (up && !expanded) || (!up && expanded) ? CaretUpIcon : CaretDownIcon

  const getOption = (language, index) => (
    <Option key={language} divider={!isUiLanguage(language) && !index}>
      <LanguageLink
        lang={language}
        href={`${ui || isUiLanguage(language) ? "" : `/en`}${links[language] || `/${language}`}`}
        className={language === activeLanguage ? "active" : null}
        {...(language === activeLanguage ? { ["aria-current"]: "page" } : {})}
      >
        <StyledFlag dropdown code={getLanguageFlag(language)} />
        <LanguageText {...(isRTL(language) ? { dir: "rtl" } : {})}>
          {getLanguageName(language)}
        </LanguageText>
      </LanguageLink>
    </Option>
  )

  return (
    <Wrapper {...props} aria-label={intl.formatMessage({ id: "languageMenu" })} expanded={expanded}>
      {expanded && (
        <Backdrop
          onClick={() => {
            setExpanded(false)
          }}
        />
      )}
      <Toggle
        lang={uiLang}
        aria-label={intl.formatMessage({ id: "openLanguageSwitcher" })}
        aria-expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        <ToggleGrid>
          <StyledFlag code={getLanguageFlag(activeLanguage)} />
          <LanguageText
            lang={activeLanguage}
            {...(isRTL(activeLanguage) ? { dir: "rtl" } : {})}
          >
            {getLanguageName(activeLanguage)}
          </LanguageText>
          <Caret />
        </ToggleGrid>
      </Toggle>
      {expanded &&
        <OptionsList up={up} left={left} ui={ui}>
          {Object.keys(uiLanguages).map(getOption)}
          {Object.keys(nonUiLanguages).map(getOption)}
        </OptionsList>
      }
    </Wrapper>
  )
}

export default function LanguageDropdownContainer(props) {
  const { translations } = usePageMetadata()
  const links = (translations || []).reduce((acc, curr) => ({
    ...acc,
    [curr.lang]: `/${curr.lang}${curr.path}`,
  }), {})

  return (
    <LanguageDropdown links={links} {...props} />
  )
}
