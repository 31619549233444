import React from "react"

const Plus = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 0C11.0286 0 11.8857 0.857143 11.8857 1.88571V8.11429H18.1143C19.2 8.11429 20 8.97143 20 10C20 11.0286 19.2 11.8857 18.1143 11.8857H11.8857V18.1143C11.8857 19.2 11.0286 20 10 20C8.97143 20 8.11429 19.2 8.11429 18.1143V11.8857H1.88571C0.857143 11.8857 0 11.0286 0 10C0 8.97143 0.857143 8.11429 1.88571 8.11429H8.11429V1.88571C8.11429 0.857143 8.97143 0 10 0Z" />
  </svg>
)

export default Plus
